const SliderData = [
     {
          img: require("../assets/photo_2024-10-01_21-24-23.jpg")
     },
     {
          img: require("../assets/photo_2024-10-01_21-24-27.jpg")
     }
     ,
     {
          img: require("../assets/photo_2024-10-01_21-24-30.jpg")
     }
     
  
]
export default SliderData